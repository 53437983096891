import * as React from 'react';

import { Unity, useUnityContext } from 'react-unity-webgl';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../App/Store';
import { isMobile } from 'react-device-detect';

import { 
    Box,
    Button,
    Link,
    Typography
} from '@mui/material';

import { Fullscreen as FullscreenIcon} from '@mui/icons-material';

import LinearProgressWithLabel from '../Shared/LinearProgressWithLabel'

import UnityAppHandler from '../../../App/UnityApp/UnityAppHandler';
import { UnityContextPaths } from '../../../App/UnityApp/Config';
import { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters';

type UnityAppPlayerProps = {
    exprimentId?: number,
    scenarioRef?: string,

    downloadOnEnd?: boolean,
    skipPreparationSteps?: boolean,
    trackProgress?: boolean
};

export default function UnityAppPlayer({ exprimentId, scenarioRef, downloadOnEnd, skipPreparationSteps, trackProgress } : UnityAppPlayerProps)
{
    const { i18n, t } = useTranslation();

    const UserState = useSelector((state : RootState) => state.User);
    const appContextHook = useUnityContext(UnityContextPaths);
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const appHandler = new UnityAppHandler(
        appContextHook,
        {
            language: i18n.language,
            authToken: UserState.token,
            userType: (UserState.loggedIn) ? UserState.userType : 'student',

            exprimentId,
            scenarioRef,

            downloadOnEnd,
            skipPreparationSteps,
            trackProgress
        }
    );

    const deepLinkUrl = new URL('practeexunitydl://neurolabo');
    if(isMobile)
    {
        const deepLinkParams = appHandler.BuildDeepLinkUrl();
        deepLinkUrl.search = deepLinkParams.toString();
    }
    
    React.useEffect(() => 
    {
        if (!appHandler.IsLoading) 
        {
            appHandler.SendConfigToWebApp();
            if (scenarioRef != "")
                appHandler.LoadLab();
        }

        return () => {
            appHandler.Unload();
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appHandler.IsLoading]);

    const Loader = (
        <Box
            position='absolute'
            sx = {{
                width: '50%' ,
                top: '45vh',
                left: '28vw'
            }}
        >
            <Typography 
                variant='body1' textAlign='center'
                color= 'text.secondary'
            >
                { t('unity_app.loading_message') }
            </Typography>
            
            <LinearProgressWithLabel value={ appHandler.LoadingProgression * 100 } />
        </Box>
    );

    return (
        <>
            { (!isMobile && appHandler.IsLoading) && Loader }
            {
                !appHandler.IsLoading && 
                <Button
                    size='large'
                    startIcon={ <FullscreenIcon fontSize='large'/> }
                    onClick={ () => appHandler.RequestFullscreen() }
                    color='secondary'
                >
                    { t('unity_app.fullscreen_button') }
                </Button>
            }
            {
                !isMobile &&
                <Unity 
                    unityProvider = { appHandler.Provider }
                    style={{ width: '100%', height: '100%' }}
                />
            }
            {
                isMobile &&
                <>
                    <Link href={deepLinkUrl.toString()}>{ t('unity_app.launch_app') }</Link>
                    <Typography variant='body1'>{ t('unity_app.application_available_on') }</Typography>
                </>
            }
            {
                (!true && !isMobile) &&
                <Typography variant='body1'>{ t('unity_app.device_not_compatible') }</Typography>
            }
        </>
    );
}